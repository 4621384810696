<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="card-title mb-0">
          View Customer Profile Details
        </div>
      </div>
      <div class="card-body">
        <AdminCustomerAddForm :customer="customer" :view="true" />
        <div>
          <button
            type="button"
            class="btn btn-lg btn-primary mt-4 mr-2"
            @click="pushToEdit"
          >
            Edit
          </button>
          <button
            type="button"
            class="btn btn-lg btn-primary mt-4 mr-2"
            @click="viewReservations"
          >
            View Customer’s Reservation
          </button>
          <button
            type="button"
            class="btn btn-lg btn-primary mt-4 mr-2"
            @click="resetPass"
            v-if="this.customer.userId && this.customer.verified"
          >
            Reset Password
          </button>
          <button
            type="button"
            class="btn btn-lg btn-primary mt-4 mr-2"
            @click="confirmAccount"
            v-if="this.customer.userId && !this.customer.verified"
          >
            Send Confirmation Email
          </button>
          <button
            class="btn btn-cancel btn-lg mt-4"
            type="button"
            @click="pushToSearch"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
    <AdminCustomerHistory :customerId="+customerId" />
  </div>
</template>

<script>
import AdminProfileService from "@/services/admin/AdminProfileService.js";
import AdminCustomerHistory from "@/components/admin/AdminCustomerHistory.vue";
import AdminCustomerAddForm from "@/components/admin/AdminCustomerAddForm.vue";
import ProfileService from "@/services/ProfileService.js";

export default {
  name: "AdminCustomerView",
  title: "Admin - Customer View",
  components: {
    AdminCustomerHistory,
    AdminCustomerAddForm
  },
  props: {
    customerId: String
  },
  data() {
    return {
      customer: {},
      externalUserId: null
    };
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    async loadCustomer() {
      let profileService = new AdminProfileService(this.tenantId);
      let loadedCustomerResponse = await profileService.getCustomerByIdAdmin(
        this.customerId
      );
      if (loadedCustomerResponse.statusCode == "Success") {
        const loadedCustomer = loadedCustomerResponse.data;
        this.externalUserId = loadedCustomer.user?.externalUserId;
        const {
          prefixName,
          firstName,
          middleName,
          lastName,
          suffixName,
          email,
          comments,
          customerStatus,
          userId,
          timezoneId,
          createdByAdmin
        } = loadedCustomer;
        this.customer = {
          prefixName,
          firstName,
          middleName,
          lastName,
          suffixName,
          email,
          comments,
          customerStatus,
          userId,
          timezoneId,
          createdByAdmin,
          verified: loadedCustomer.user?.verified
        };
        if (
          loadedCustomer.addresses.filter(x => x.addressTypeId === 1).length > 0
        ) {
          const address = loadedCustomer.addresses.filter(
            x => x.addressTypeId === 1
          )[0];
          const {
            id,
            street1,
            street2,
            city,
            state,
            zipcode,
            country
          } = address;
          this.customer = {
            ...this.customer,
            addressId: id,
            street1,
            street2,
            city,
            state: state.trim(),
            zipCode: zipcode.trim(),
            country
          };
        }
        if (
          loadedCustomer.customerPhoneNumbers.filter(
            x => x.isPrimary && x.phoneNumberTypeId === 1
          ).length > 0
        ) {
          const primary = loadedCustomer.customerPhoneNumbers.filter(
            x => x.isPrimary && x.phoneNumberTypeId === 1
          )[0];
          this.customer.primaryPhoneNumber = primary.number;
          this.customer.primaryPhoneNumberId = primary.id;
        }
        if (
          loadedCustomer.customerPhoneNumbers.filter(
            x => x.phoneNumberTypeId === 2
          ).length > 0
        ) {
          const alternate = loadedCustomer.customerPhoneNumbers.filter(
            x => x.phoneNumberTypeId === 2
          )[0];
          this.customer.alternatePhoneNumber = alternate.number;
          this.customer.alternatePhoneNumberId = alternate.id;
        }
        if (loadedCustomer.user && loadedCustomer.user.username) {
          this.customer.username = loadedCustomer.user.username;
        }
      }
    },
    async resetPass() {
      const profileService = new ProfileService(this.tenantId);
      const user = await profileService.forgotPassword(this.customer.username);
      if (user) {
        window.scrollTo(0, 0);
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-info",
          message:
            "Password reset submitted. If an email is associated with this username, a link will be sent to complete the password reset process.",
          layer: "admin"
        });
      }
    },
    async confirmAccount() {
      const profileService = new ProfileService(this.tenantId);
      await profileService.resendRegistrationCode(this.externalUserId);
      window.scrollTo(0, 0);
      this.$store.commit("alert/setErrorAlert", {
        type: "alert-info",
        message:
          "Account confirmation link resent. Previous link will not be valid...",
        layer: "admin"
      });
    },
    pushToSearch() {
      this.$router.push("/admin/customer-search").catch(() => {});
    },
    pushToEdit() {
      this.$router
        .push(`/admin/customer-edit/${this.customerId}`)
        .catch(() => {});
    },
    viewReservations() {
      this.$router
        .push(`/admin/reservation-search?customerId=${this.customerId}`)
        .catch(() => {});
    }
  },
  mounted() {
    this.loadCustomer();
  }
};
</script>

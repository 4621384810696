<template>
  <div class="card">
    <div class="card-header">
      <a @click="toggleExpansion">
        <h4 class="card-title mt-2 mb-0">
          Customer History
          <i class="fa float-right" :class="icon"></i>
        </h4>
      </a>
    </div>
    <div class="card-body" v-if="this.showHistory">
      <div class="row" v-if="history">
        <b-table
          ref="table"
          striped
          :items="history"
          :fields="fields"
          stacked="md"
          small
          bordered
          sort-icon-left
          v-if="history && history.length > 0"
        >
          <template v-slot:cell(date)="data">
            {{ data.item.date | formatUTCDateTime }}
          </template>
        </b-table>
        <p class="mx-auto" v-if="history && history.length === 0">
          No history found
        </p>
      </div>
      <div v-else class="row">
        <span
          class="spinner-border spinner-border-sm mx-auto"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import AdminProfileService from "@/services/admin/AdminProfileService.js";
export default {
  name: "AdminCustomerHistory",
  props: {
    customerId: Number
  },
  data() {
    return {
      showHistory: false,
      history: null,
      fields: [
        {
          key: "date",
          label: "Date",
          class: "dateColumn",
          sortable: true
        },
        {
          key: "user",
          label: "User",
          class: "userColumn",
          sortable: true
        },
        {
          key: "fieldChanged",
          label: "Field Changed",
          sortable: true
        },
        {
          key: "previousValue",
          label: "Previous Value",
          sortable: true
        },
        {
          key: "newValue",
          label: "New Value",
          sortable: true
        }
      ]
    };
  },
  computed: {
    icon() {
      if (this.showHistory) {
        return "fa-angle-down";
      }
      return "fa-angle-up";
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    async toggleExpansion() {
      this.showHistory = !this.showHistory;
      if (this.showHistory) {
        await this.getHistory();
      }
    },
    async getHistory() {
      const profileService = new AdminProfileService(this.tenantId);
      if (this.customerId) {
        const response = await profileService.getCustomerHistory(
          this.customerId
        );
        this.history = response.data;
      }
    },
    formatFieldName(fieldName) {
      let fieldNameWithSpaces = fieldName.replace("_", " ");
      return fieldNameWithSpaces.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
    }
  }
};
</script>

<style>
.dateColumn {
  width: 200px !important;
}
.userColumn {
  width: 400px !important;
}
</style>
